var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    { attrs: { md: _vm.columnCount } },
    [
      _c(
        "div",
        { staticClass: "upsell-edit-card" },
        [
          _c(
            "v-card",
            { staticClass: "elevation-1" },
            [
              !_vm.exclusionary
                ? _c(
                    "v-card-title",
                    { staticClass: "pb-0 mb-1 justify-center" },
                    [_vm._v(_vm._s(`Position ${_vm.position}`))]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  directives: [{ name: "bar", rawName: "v-bar" }],
                  class: _vm.exclusionary ? "" : "scroll-container",
                },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-list-item",
                        { staticClass: "tile" },
                        [
                          _c("v-list-item-action"),
                          _c(
                            "v-row",
                            {
                              staticClass: "mb-0",
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { md: "5" } },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v("Item Name"),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { md: "3" } },
                                [_c("v-list-item-title", [_vm._v("Item ID")])],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { md: "2" } },
                                [_c("v-list-item-title", [_vm._v("Cost")])],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { md: "2" } },
                                [_c("v-list-item-title")],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.localItems.length
                        ? _c(
                            "v-list",
                            {
                              ref: "sortableList",
                              staticClass: "el2 pt-0",
                              attrs: { "hide-actions": "" },
                            },
                            _vm._l(_vm.localItems, function (item) {
                              return _c(
                                "v-list-item",
                                { key: item.id, staticClass: "tile" },
                                [
                                  _c("v-list-item-action", [
                                    _c("div", {
                                      staticClass: "icon icon--handle",
                                    }),
                                  ]),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mb-0",
                                      attrs: { "no-gutters": "" },
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { md: "5" } },
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              _vm._s(item.inventoryItemName)
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { md: "3" } },
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(
                                              _vm._s(item.InventoryItemID)
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { md: "2" } },
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(_vm._s(item.costDisplay)),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { md: "2" } },
                                        [
                                          _c("v-list-item-title", [
                                            _c("div", {
                                              staticClass: "delete-logo",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteItem(item)
                                                },
                                              },
                                            }),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          )
                        : _c("v-card-text", [_vm._v(_vm._s(_vm.emptyText))]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "action-row",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.onAdd.apply(null, arguments)
                        },
                      },
                    },
                    [
                      !_vm.exclusionary
                        ? _c("div", { staticClass: "upsell-logo" })
                        : _c("div", { staticClass: "upsell-exclude" }),
                      !_vm.exclusionary
                        ? _c("div", { staticClass: "action-row__title" }, [
                            _vm._v(" " + _vm._s(_vm.selectMoreItemsText) + " "),
                          ])
                        : _c(
                            "div",
                            { staticClass: "action-row__exclusionary_title" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.selectMoreItemsText) + " "
                              ),
                            ]
                          ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.upsellSelectionVisible
        ? _c(
            "EditingModal",
            { staticClass: "modal" },
            [
              _c("UpsellSelectionCard", {
                attrs: {
                  title: _vm.selectionCardTitle,
                  preSelectedItems: _vm.modalItems,
                  includeGroups: true,
                },
                on: { close: _vm.closeModal, save: _vm.handleNewSelections },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }