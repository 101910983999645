<template>
  <v-col :md="columnCount">
    <div class="upsell-edit-card">
      <v-card class="elevation-1">
        <v-card-title v-if="!exclusionary" class="pb-0 mb-1 justify-center">{{
          `Position ${position}`
        }}</v-card-title>
        <div v-bar :class=" exclusionary ? '' : 'scroll-container'">
          <v-card-text class="pa-0">
            <v-list-item class="tile">
              <v-list-item-action></v-list-item-action>
              <v-row no-gutters class="mb-0">
                <v-col md="5">
                  <v-list-item-title>Item Name</v-list-item-title>
                </v-col>
                <v-col md="3">
                  <v-list-item-title>Item ID</v-list-item-title>
                </v-col>
                <v-col md="2">
                  <v-list-item-title>Cost</v-list-item-title>
                </v-col>
                <v-col md="2">
                  <v-list-item-title></v-list-item-title>
                </v-col>
              </v-row>
            </v-list-item>
            <v-list
              v-if="localItems.length"
              ref="sortableList"
              hide-actions
              class="el2 pt-0"
            >
              <v-list-item
                v-for="item in localItems"
                :key="item.id"
                class="tile"
              >
                <v-list-item-action>
                  <div class="icon icon--handle"></div>
                </v-list-item-action>
                <v-row no-gutters class="mb-0">
                  <v-col md="5">
                    <v-list-item-title>{{
                      item.inventoryItemName
                    }}</v-list-item-title>
                  </v-col>
                  <v-col md="3">
                    <v-list-item-title>{{
                      item.InventoryItemID
                    }}</v-list-item-title>
                  </v-col>
                  <v-col md="2">
                    <v-list-item-title>{{
                      item.costDisplay
                    }}</v-list-item-title>
                  </v-col>
                  <v-col md="2">
                    <v-list-item-title>
                      <div @click="deleteItem(item)" class="delete-logo" />
                    </v-list-item-title>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list>
            <v-card-text v-else>{{ emptyText }}</v-card-text>
          </v-card-text>
        </div>

        <v-card-actions>
          <v-row class="action-row" @click.stop="onAdd">
            <div v-if="!exclusionary" class="upsell-logo" />
            <div v-else class="upsell-exclude" />

            <div v-if="!exclusionary" class="action-row__title">
              {{ selectMoreItemsText }}
            </div>
            <div v-else class="action-row__exclusionary_title" >
              {{ selectMoreItemsText }}
            </div>
          </v-row>
        </v-card-actions>
      </v-card>
    </div>
    <EditingModal v-if="upsellSelectionVisible" class="modal">
      <UpsellSelectionCard
        :title="selectionCardTitle"
        :preSelectedItems="modalItems"
        :includeGroups="true"
        @close="closeModal"
        @save="handleNewSelections"
      />
    </EditingModal>
  </v-col>
</template>

<script>
import Sortable from "sortablejs";
import UpsellSelectionCard from "components/upsell/upsell_selection_card.vue";
import EditingModal from "components/editing_modal.vue";
import clone from "clone";
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            localItems: this.items,
            modalItems: clone(this.items),
            upsellSelectionVisible: false,
            sortable: undefined,
        };
    },
    props: {
        target: {
            type: String,
            required: true,
        },
        position: {
            type: Number,
            required: false,
        },
        items: {
            type: Array,
            required: true,
        },
        emptyText: {
            type: String,
            required: true,
        },
        selectMoreItemsText: {
            type: String,
            required: true,
        },
        exclusionary: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        UpsellSelectionCard,
        EditingModal,
    },
    computed: {
        selectionCardTitle() {
            if (this.position && !this.exclusionary)
                return `Select upsell items for ${this.target} (Position ${this.position})`;
            else if (this.exclusionary)
                return this.selectMoreItemsText
        },
        columnCount() {
            return this.exclusionary ? 12 : 6;
        },
        ...mapGetters(["productsMap"]),
    },
    methods: {
        dragReorder({ oldIndex, newIndex }) {
            const movedItem = this.localItems.splice(oldIndex, 1)[0];
            this.localItems.splice(newIndex, 0, movedItem);
            this.handleChange();
        },
        onAdd() {
            this.modalItems = clone(this.localItems);
            this.upsellSelectionVisible = true;
        },
        deleteItem(item) {
            let index = this.localItems.findIndex((el) => el.id == item.id);
            this.localItems.splice(index, 1);
            this.handleChange();
        },
        closeModal() {
            this.upsellSelectionVisible = false;
        },
        handleNewSelections(selectedItems) {
            this.upsellSelectionVisible = false;
            this.localItems = selectedItems
                .filter((el) => !el.group)
                .map((el) => {
                    return {
                        InventoryItemID: el.id,
                        Order: el.order == undefined ? -1 : el.order,
                        Position: this.upsellSelectionPosition,
                        ...this.productsMap[`${el.id}`],
                    };
                });
            this.handleChange();
        },
        handleChange() {
            this.$emit("onChange", this.position, this.localItems);
            this.installSortable();
        },
        installSortable() {
            this.$nextTick(() => {
                if (this.$refs.sortableList === undefined) return;
                if (this.sortable != undefined) {
                    this.sortable.destroy();
                }
                this.sortable = new Sortable(this.$refs.sortableList.$el, {
                    handle: ".icon--handle",
                    onStart: () => {},
                    onEnd: this.dragReorder,
                });
            });
        },
    },
    mounted() {
        this.installSortable();
    },
    watch: {
        items(newValue, oldValue) {
            this.localItems = newValue;
            this.installSortable();
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~scss/mixins";
@import "~scss/variables";
@import "~css/vuebar.css";

.action-row {
  border-top: 1px solid #e4e4e4;
  padding: 15px;
  cursor: pointer;
  margin-left: -8px !important;
  margin-right: -8px !important;
  &__title {
    margin-left: 10px;
    color: $brand_color !important;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
  }
  &__exclusionary_title {
    margin-left: 10px;
    color: red !important;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
  }
}
.upsell-edit-card {
  .tile {
    border-bottom: 1px solid #e4e4e4;
  }

  .header {
    .v-list__tile {
      color: purple;
    }
  }
  .flex.md5 {
    max-width: 25% !important;
  }
}
.scroll-container {
  height: 255px;
  margin-bottom: 5px;
}

.v-card {
  &__title {
    color: #585858 !important;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
  }

  &__text {
    color: #585858 !important;
    font-size: 14px;
    overflow: hidden;
  }
}

.v-list {
  margin-right: 5px;

  &__tile {
    border-bottom: 1px solid #e4e4e4;

    &__title {
      color: #585858 !important;
      font-size: 14px;
    }

    &__action {
      min-width: 35px;
      margin-left: 5px;
      .icon {
        cursor: grab;
      }
    }
  }
}

.upsell-logo {
  @include background-image("", "add_new_upsell.svg", "../../img/");
  margin-left: 5px;
  flex-grow: 0 !important;
  width: spacing(sm);
  height: spacing(sm);
  background-size: contain;
}
.upsell-exclude {
  @include background-image("", "upsell_exclude.svg", "../../img/");

  flex-grow: 0 !important;
  width: spacing(lg);
  height: spacing(lg);
  background-size: contain;
}
.delete-logo {
  @include background-image("", "delete.svg", "../../img/");
  background-size: 25px;
  cursor: pointer;
  flex-grow: 0 !important;
  margin-left: 10px;
  padding: 10px;
  width: 25px;
  height: 25px;
  margin-top: -2px;
}
</style>
